<template>
  <div class="profile-body" v-if="$oidc.isAuthenticated &&
    $oidc.userProfile &&
    $oidc.userProfile.roles &&
    $oidc.userProfile.roles.length > 0
    ">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="edit-con button-reg">
      <b-button pill class="asmt-bt my-btn" size="lg" @click="myProfile()">
        <img alt="profile" src="../../assets/profile.svg" />
        <span class="primary-color btn-text">Edit My Profile</span>
      </b-button>
      <b-button pill class="asmt-bt" size="lg" @click="editUser()" :disabled="checkPersonaForEditProBtn">
        <img alt="mul_users" src="../../assets/mul_users.svg" />
        <span class="primary-color btn-text">Edit Users Profile</span>
      </b-button>

      <b-button pill class="asmt-bt" size="lg" @click="configProfile()" :disabled="checkPersonaForEditProBtn"
        style="margin-left: 10px">
        <img alt="profile" src="../../assets/profile.svg" />
        <span class="primary-color btn-text"> Configure Users </span>
        <span v-if="configureUserCount" class="
            position-absolute
            top-0
            start-100
            badge
            rounded-pill
            bg-danger
            notification-count
            notfn-cnt
          ">{{ configureUserCount }}</span>
      </b-button>
    </div>
    <div class="main-con">
      <MyProfile />

      <div class="role-btn-con" v-if="profileHierarchy.globalPillarLeaders &&
        profileHierarchy.globalPillarLeaders.length > 0
        ">
        <div class="my-tm-togg-con">
          <img alt="more" v-if="!globalPOPillarLeaders" @click="globalPOPillarLeaders = !globalPOPillarLeaders"
            class="my-tm-togg-img" src="../../assets/Arrow_down.svg" />
          <img alt="more" v-if="globalPOPillarLeaders" @click="globalPOPillarLeaders = !globalPOPillarLeaders"
            class="my-tm-togg-img" src="../../assets/Arrow_up.svg" />
        </div>
        <div class="role-name">Global Pillar Leaders</div>
      </div>
      <div v-if="globalPOPillarLeaders &&
        profileHierarchy.globalPillarLeaders &&
        profileHierarchy.globalPillarLeaders.length > 0
        " class="profiles-con">
        <div>
          <div class="row">
            <div class="col-4" v-for="(globalPOL, index) in profileHierarchy.globalPillarLeaders"
              :key="globalPOL.userId + '-' + index">
              <div class="img-con single-profile brown-pro">
                <div v-if="globalPOL.profilePicUrl">
                  <b-img :src="globalPOL.profilePicUrl" rounded="circle" alt="Rounded image" width="75" height="75"
                    class="pro-img-border" @error="setAltImg('globalPillarLeaders', index)"></b-img>
                </div>
                <div class="no-img" v-else>
                  <span class="profile-sh">{{
                    globalPOL.userName | shortName
                  }}</span>
                </div>
                <div class="details-con">
                  <div class="sec-name">{{ globalPOL.userName }}</div>
                  <div class="sec-bu">
                    <span class="pillar-code" v-if="globalPOL.title">{{
                      globalPOL.title
                    }}</span>
                  </div>
                  <div class="sec-bu">
                    <span class="pillar-code">{{ globalPOL.pillarCode }}</span>
                  </div>

                  <div class="d-flex pf-dt-sb">
                    <img alt="email" src="../../assets/email.svg" />
                    <span class="sec-otr m-lt-loc">{{
                      globalPOL.emailId
                    }}</span>
                  </div>
                  <div class="d-flex pf-dt-sb">
                    <img alt="phone" src="../../assets/phone.svg" />
                    <span class="sec-otr m-lt-loc"><span v-if="globalPOL.countryCode">{{ globalPOL.countryCode }} -
                      </span>{{ globalPOL.contactNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="role-btn-con" v-if="profileHierarchy.regionalPOLeader &&
        profileHierarchy.regionalPOLeader.length > 0
        ">
        <div class="my-tm-togg-con">
          <img alt="more" v-if="!regionaPOManger" @click="regionaPOManger = !regionaPOManger" class="my-tm-togg-img"
            src="../../assets/Arrow_down.svg" />
          <img alt="more" v-if="regionaPOManger" @click="regionaPOManger = !regionaPOManger" class="my-tm-togg-img"
            src="../../assets/Arrow_up.svg" />
        </div>
        <div class="role-name">Regional PO Leaders</div>
      </div>
      <div v-if="regionaPOManger &&
        profileHierarchy.regionalPOLeader &&
        profileHierarchy.regionalPOLeader.length > 0
        " class="profiles-con">
        <div>
          <div class="row">
            <div class="col-4" v-for="(regionaPO, index) in profileHierarchy.regionalPOLeader"
              :key="regionaPO.userId + '-' + index">
              <div class="img-con single-profile green-pro">
                <div v-if="regionaPO.profilePicUrl">
                  <b-img :src="regionaPO.profilePicUrl" rounded="circle" alt="Rounded image" width="75" height="75"
                    class="pro-img-border" @error="setAltImg('regionalPOLeader', index)"></b-img>
                </div>
                <div class="no-img" v-else>
                  <span class="profile-sh">{{
                    regionaPO.userName | shortName
                  }}</span>
                </div>
                <div class="details-con">
                  <div class="sec-name">{{ regionaPO.userName }}</div>
                  <div class="sec-bu">
                    <span class="pillar-code" v-if="regionaPO.title">{{
                      regionaPO.title
                    }}</span>
                  </div>
                  <div class="sec-bu">{{ regionaPO.bussinessUnit }}</div>

                  <div class="d-flex m-top">
                    <img alt="email" src="../../assets/email.svg" />
                    <span class="sec-otr m-lt-loc">{{
                      regionaPO.emailId
                    }}</span>
                  </div>
                  <div class="d-flex">
                    <img alt="phone" src="../../assets/phone.svg" />
                    <span class="sec-otr m-lt-loc"><span v-if="regionaPO.countryCode">{{ regionaPO.countryCode }} -
                      </span>{{ regionaPO.contactNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="role-btn-con" v-if="profileHierarchy.regionalPillarLeaders &&
        profileHierarchy.regionalPillarLeaders.length > 0
        ">
        <div class="my-tm-togg-con">
          <img alt="more" v-if="!regionalPOPillarLeaders" @click="regionalPOPillarLeaders = !regionalPOPillarLeaders"
            class="my-tm-togg-img" src="../../assets/Arrow_down.svg" />
          <img alt="more" v-if="regionalPOPillarLeaders" @click="regionalPOPillarLeaders = !regionalPOPillarLeaders"
            class="my-tm-togg-img" src="../../assets/Arrow_up.svg" />
        </div>
        <div class="role-name">Regional Pillar Leaders</div>
      </div>
      <div v-if="regionalPOPillarLeaders &&
        profileHierarchy.regionalPillarLeaders &&
        profileHierarchy.regionalPillarLeaders.length > 0
        " class="profiles-con">
        <div>
          <div class="row">
            <div class="col-4" v-for="(
                regionaPL, index
              ) in profileHierarchy.regionalPillarLeaders" :key="regionaPL.userId + '-' + index">
              <div class="img-con single-profile brown-pro">
                <div v-if="regionaPL.profilePicUrl">
                  <b-img :src="regionaPL.profilePicUrl" rounded="circle" alt="Rounded image" width="75" height="75"
                    class="pro-img-border" @error="setAltImg('regionalPillarLeaders', index)"></b-img>
                </div>
                <div class="no-img" v-else>
                  <span class="profile-sh">{{
                    regionaPL.userName | shortName
                  }}</span>
                </div>
                <div class="details-con">
                  <div class="sec-name">{{ regionaPL.userName }}</div>
                  <div class="sec-bu">
                    <span class="pillar-code" v-if="regionaPL.title">{{
                      regionaPL.title
                    }}</span>
                  </div>
                  <div class="sec-bu">
                    <span class="pillar-code">{{ regionaPL.pillarCode }}</span><span
                      v-if="regionaPL.pillarCode && regionaPL.bussinessUnit">, </span>{{ regionaPL.bussinessUnit }}
                  </div>

                  <div class="d-flex pf-dt-sb">
                    <img alt="email" src="../../assets/email.svg" />
                    <span class="sec-otr m-lt-loc">{{
                      regionaPL.emailId
                    }}</span>
                  </div>
                  <div class="d-flex pf-dt-sb">
                    <img alt="phone" src="../../assets/phone.svg" />
                    <span class="sec-otr m-lt-loc"><span v-if="regionaPL.countryCode">{{ regionaPL.countryCode }} -
                      </span>{{ regionaPL.contactNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="role-btn-con" v-if="profileHierarchy.plantPOLeaders &&
        profileHierarchy.plantPOLeaders.length > 0
        ">
        <div class="my-tm-togg-con">
          <img alt="more" v-if="!plantPLTLeaders" @click="plantPLTLeaders = !plantPLTLeaders" class="my-tm-togg-img"
            src="../../assets/Arrow_down.svg" />
          <img alt="more" v-if="plantPLTLeaders" @click="plantPLTLeaders = !plantPLTLeaders" class="my-tm-togg-img"
            src="../../assets/Arrow_up.svg" />
        </div>
        <div class="role-name">Plant PO Leaders</div>
      </div>
      <div v-if="plantPLTLeaders &&
        profileHierarchy.plantPOLeaders &&
        profileHierarchy.plantPOLeaders.length > 0
        " class="profiles-con">
        <div>
          <div class="row">
            <div class="col-4" v-for="(plantPOLeaders, index) in profileHierarchy.plantPOLeaders"
              :key="plantPOLeaders.userId + '-' + index">
              <div class="img-con single-profile brown-pro">
                <div v-if="plantPOLeaders.profilePicUrl">
                  <b-img :src="plantPOLeaders.profilePicUrl" rounded="circle" alt="Rounded image" width="75" height="75"
                    class="pro-img-border" @error="setAltImg('plantPOLeaders', index)"></b-img>
                </div>
                <div class="no-img" v-else>
                  <span class="profile-sh">{{
                    plantPOLeaders.userName | shortName
                  }}</span>
                </div>
                <div class="details-con">
                  <div class="sec-name">{{ plantPOLeaders.userName }}</div>
                  <div class="sec-bu">
                    <span class="pillar-code" v-if="plantPOLeaders.title">{{
                      plantPOLeaders.title
                    }}</span>
                  </div>
                  <div class="sec-bu">{{ plantPOLeaders.bussinessUnit }}</div>

                  <div class="d-flex pf-dt-sb">
                    <img alt="email" src="../../assets/email.svg" />
                    <span class="sec-otr m-lt-loc">{{
                      plantPOLeaders.emailId
                    }}</span>
                  </div>
                  <div class="d-flex pf-dt-sb">
                    <img alt="phone" src="../../assets/phone.svg" />
                    <span class="sec-otr m-lt-loc"><span v-if="plantPOLeaders.countryCode">{{
                      plantPOLeaders.countryCode
                    }} - </span>{{ plantPOLeaders.contactNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="role-btn-con" v-if="profileHierarchy.plantManager &&
        profileHierarchy.plantManager.length > 0
        ">
        <div class="my-tm-togg-con">
          <img alt="more" v-if="plantPoLeader" @click="plantPoLeader = !plantPoLeader" class="my-tm-togg-img"
            src="../../assets/Arrow_down.svg" />
          <img alt="more" v-if="!plantPoLeader" @click="plantPoLeader = !plantPoLeader" class="my-tm-togg-img"
            src="../../assets/Arrow_up.svg" />
        </div>
        <div class="role-name">Plant Manager</div>
      </div>
      <div v-if="plantPoLeader &&
        profileHierarchy.plantManager &&
        profileHierarchy.plantManager.length > 0
        " class="profiles-con">
        <div>
          <div class="row">
            <div class="col-4" v-for="(poLeader, index) in profileHierarchy.plantManager"
              :key="poLeader.userId + '-' + index">
              <div class="img-con single-profile green-pro">
                <div v-if="poLeader.profilePicUrl">
                  <b-img :src="poLeader.profilePicUrl" rounded="circle" alt="Rounded image" width="75" height="75"
                    class="pro-img-border" @error="setAltImg('plantManager', index)"></b-img>
                </div>
                <div class="no-img" v-else>
                  <span class="profile-sh">{{
                    poLeader.userName | shortName
                  }}</span>
                </div>
                <div class="details-con">
                  <div class="sec-name">{{ poLeader.userName }}</div>
                  <div class="sec-bu">
                    <span class="pillar-code" v-if="poLeader.title">{{
                      poLeader.title
                    }}</span>
                  </div>
                  <div class="sec-bu">{{ poLeader.pillarName }}</div>

                  <div class="d-flex m-top">
                    <img alt="email" src="../../assets/email.svg" />
                    <span class="sec-otr m-lt-loc">{{ poLeader.emailId }}</span>
                  </div>
                  <div class="d-flex">
                    <img alt="phone" src="../../assets/phone.svg" />
                    <span class="sec-otr m-lt-loc"><span v-if="poLeader.countryCode">{{ poLeader.countryCode }} -
                      </span>
                      {{ poLeader.contactNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="role-btn-con" v-if="profileHierarchy.plantPillarLeaders &&
        profileHierarchy.plantPillarLeaders.length > 0
        ">
        <div class="my-tm-togg-con">
          <img alt="more" v-if="!plantPoPillarLeader" @click="plantPoPillarLeader = !plantPoPillarLeader"
            class="my-tm-togg-img" src="../../assets/Arrow_down.svg" />
          <img alt="more" v-if="plantPoPillarLeader" @click="plantPoPillarLeader = !plantPoPillarLeader"
            class="my-tm-togg-img" src="../../assets/Arrow_up.svg" />
        </div>
        <div class="role-name">Plant Pillar Leaders</div>
      </div>
      <div v-if="plantPoPillarLeader &&
        profileHierarchy.plantPillarLeaders &&
        profileHierarchy.plantPillarLeaders.length > 0
        " class="profiles-con">
        <div>
          <div class="row">
            <div class="col-4" v-for="(plantPL, index) in profileHierarchy.plantPillarLeaders"
              :key="plantPL.userId + '-' + index">
              <div class="img-con single-profile brown-pro">
                <div v-if="plantPL.profilePicUrl">
                  <b-img :src="plantPL.profilePicUrl" rounded="circle" alt="Rounded image" width="75" height="75"
                    class="pro-img-border" @error="setAltImg('plantPillarLeaders', index)"></b-img>
                </div>
                <div class="no-img" v-else>
                  <span class="profile-sh">{{
                    plantPL.userName | shortName
                  }}</span>
                </div>
                <div class="details-con">
                  <div class="sec-name">{{ plantPL.userName }}</div>
                  <div class="sec-bu">
                    <span class="pillar-code" v-if="plantPL.title">{{
                      plantPL.title
                    }}</span>
                  </div>
                  <div class="sec-bu">
                    <span class="pillar-code">{{ plantPL.pillarCode }}</span><span
                      v-if="plantPL.pillarCode && plantPL.bussinessUnit">, </span>{{ plantPL.bussinessUnit }}
                  </div>

                  <div class="d-flex pf-dt-sb">
                    <img alt="email" src="../../assets/email.svg" />
                    <span class="sec-otr m-lt-loc">{{ plantPL.emailId }}</span>
                  </div>
                  <div class="d-flex pf-dt-sb">
                    <img alt="phone" src="../../assets/phone.svg" />
                    <span class="sec-otr m-lt-loc"><span v-if="plantPL.countryCode">{{ plantPL.countryCode }} -
                      </span>{{ plantPL.contactNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="role-btn-con" v-if="profileHierarchy.plantSubPillarLeaders &&
        profileHierarchy.plantSubPillarLeaders.length > 0
        ">
        <div class="my-tm-togg-con">
          <img alt="more" v-if="!plantSubPillarLeaders" @click="plantSubPillarLeaders = !plantSubPillarLeaders"
            class="my-tm-togg-img" src="../../assets/Arrow_down.svg" />
          <img alt="more" v-if="plantSubPillarLeaders" @click="plantSubPillarLeaders = !plantSubPillarLeaders"
            class="my-tm-togg-img" src="../../assets/Arrow_up.svg" />
        </div>
        <div class="role-name">Plant Sub Pillar Leaders</div>
      </div>
      <div v-if="plantSubPillarLeaders &&
        profileHierarchy.plantSubPillarLeaders &&
        profileHierarchy.plantSubPillarLeaders.length > 0
        " class="profiles-con">
        <div>
          <div class="row">
            <div class="col-4" v-for="(plantPL, index) in profileHierarchy.plantSubPillarLeaders"
              :key="plantPL.userId + '-' + index">
              <div class="img-con single-profile brown-pro">
                <div v-if="plantPL.profilePicUrl">
                  <b-img :src="plantPL.profilePicUrl" rounded="circle" alt="Rounded image" width="75" height="75"
                    class="pro-img-border" @error="setAltImg('plantSubPillarLeaders', index)"></b-img>
                </div>
                <div class="no-img" v-else>
                  <span class="profile-sh">{{
                    plantPL.userName | shortName
                  }}</span>
                </div>
                <div class="details-con">
                  <div class="sec-name">{{ plantPL.userName }}</div>
                  <div class="sec-bu">
                    <span class="pillar-code" v-if="plantPL.title">{{
                      plantPL.title
                    }}</span>
                  </div>
                  <div class="sec-bu">
                    <span class="pillar-code">{{ plantPL.pillarCode }}</span><span
                      v-if="plantPL.pillarCode && plantPL.bussinessUnit">, </span>{{ plantPL.bussinessUnit }}
                  </div>

                  <div class="d-flex pf-dt-sb">
                    <img alt="email" src="../../assets/email.svg" />
                    <span class="sec-otr m-lt-loc">{{ plantPL.emailId }}</span>
                  </div>
                  <div class="d-flex pf-dt-sb">
                    <img alt="phone" src="../../assets/phone.svg" />
                    <span class="sec-otr m-lt-loc"><span v-if="plantPL.countryCode">{{ plantPL.countryCode }} -
                      </span>{{ plantPL.contactNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="role-btn-con" v-if="profileHierarchy.associates && profileHierarchy.associates.length > 0
        ">
        <div class="my-tm-togg-con">
          <img alt="more" v-if="associateUser" @click="associateUser = !associateUser" class="my-tm-togg-img"
            src="../../assets/Arrow_up.svg" />
          <img alt="more" v-if="!associateUser" @click="associateUser = !associateUser" class="my-tm-togg-img"
            src="../../assets/Arrow_down.svg" />
        </div>
        <div class="role-name">Associates</div>
      </div>
      <div v-if="associateUser &&
        profileHierarchy.associates &&
        profileHierarchy.associates.length > 0
        " class="profiles-con">
        <div>
          <div class="row">
            <div class="col-4" v-for="(user, index) in profileHierarchy.associates" :key="user.userId + '-' + index">
              <div class="img-con single-profile brown-pro">
                <div v-if="user.profilePicUrl">
                  <b-img :src="user.profilePicUrl" rounded="circle" alt="Rounded image" width="75" height="75"
                    class="pro-img-border" @error="setAltImg('associates', index)"></b-img>
                </div>
                <div class="no-img" v-else>
                  <span class="profile-sh">{{
                    user.userName | shortName
                  }}</span>
                </div>
                <div class="details-con">
                  <div class="sec-name">{{ user.userName }}</div>
                  <div class="sec-bu">
                    <span class="pillar-code" v-if="user.title">{{
                      user.title
                    }}</span>
                  </div>
                  <div class="sec-bu">{{ user.bussinessUnit }}</div>

                  <div class="d-flex pf-dt-sb">
                    <img alt="email" src="../../assets/email.svg" />
                    <span class="sec-otr m-lt-loc">{{ user.emailId }}</span>
                  </div>
                  <div class="d-flex pf-dt-sb">
                    <img alt="phone" src="../../assets/phone.svg" />
                    <span class="sec-otr m-lt-loc"><span v-if="user.countryCode">{{ user.countryCode }} - </span>{{
                      user.contactNumber
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="role-btn-con" v-if="profileHierarchy.executive && profileHierarchy.executive.length > 0
          ">
        <div class="my-tm-togg-con">
          <img alt="more" v-if="executiveUser" @click="executiveUser = !executiveUser" class="my-tm-togg-img"
            src="../../assets/Arrow_up.svg" />
          <img alt="more" v-if="!executiveUser" @click="executiveUser = !executiveUser" class="my-tm-togg-img"
            src="../../assets/Arrow_down.svg" />
        </div>
        <div class="role-name">Executives</div>
      </div>
      <div v-if="executiveUser &&
          profileHierarchy.executive &&
          profileHierarchy.executive.length > 0
          " class="profiles-con">
        <div>
          <div class="row">
            <div class="col-4" v-for="(user, index) in profileHierarchy.executive" :key="user.userId + '-' + index">
              <div class="img-con single-profile brown-pro">
                <div v-if="user.profilePicUrl">
                  <b-img :src="user.profilePicUrl" rounded="circle" alt="Rounded image" width="75" height="75"
                    class="pro-img-border" @error="setAltImg('executive', index)"></b-img>
                </div>
                <div class="no-img" v-else>
                  <span class="profile-sh">{{
                    user.userName | shortName
                  }}</span>
                </div>
                <div class="details-con">
                  <div class="sec-name">{{ user.userName }}</div>
                  <div class="sec-bu">
                    <span class="pillar-code" v-if="user.title">{{
                      user.title
                    }}</span>
                  </div>
                  <div class="sec-bu">{{ user.bussinessUnit }}</div>

                  <div class="d-flex pf-dt-sb">
                    <img alt="email" src="../../assets/email.svg" />
                    <span class="sec-otr m-lt-loc">{{ user.emailId }}</span>
                  </div>
                  <div class="d-flex pf-dt-sb">
                    <img alt="email" src="../../assets/phone.svg" />
                    <span class="sec-otr m-lt-loc"><span v-if="user.countryCode">{{ user.countryCode }} - </span>{{
                      user.contactNumber
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="role-btn-con" v-if="profileHierarchy.corporates && profileHierarchy.corporates.length > 0
          ">
        <div class="my-tm-togg-con">
          <img alt="more" v-if="corp" @click="corp = !corp" class="my-tm-togg-img" src="../../assets/Arrow_up.svg" />
          <img alt="more" v-if="!corp" @click="corp = !corp" class="my-tm-togg-img" src="../../assets/Arrow_down.svg" />
        </div>
        <div class="role-name">Corporates</div>
      </div>
      <div v-if="corp &&
          profileHierarchy.corporates &&
          profileHierarchy.corporates.length > 0
          " class="profiles-con">
        <div>
          <div class="row">
            <div class="col-4" v-for="(user, index) in profileHierarchy.corporates" :key="user.userId + '-' + index">
              <div class="img-con single-profile brown-pro">
                <div v-if="user.profilePicUrl">
                  <b-img :src="user.profilePicUrl" rounded="circle" alt="Rounded image" width="75" height="75"
                    class="pro-img-border" @error="setAltImg('executive', index)"></b-img>
                </div>
                <div class="no-img" v-else>
                  <span class="profile-sh">{{
                    user.userName | shortName
                  }}</span>
                </div>
                <div class="details-con">
                  <div class="sec-name">{{ user.userName }}</div>
                  <div class="sec-bu">{{ user.bussinessUnit }}</div>

                  <div class="d-flex pf-dt-sb">
                    <img alt="email" src="../../assets/email.svg" />
                    <span class="sec-otr m-lt-loc">{{ user.emailId }}</span>
                  </div>
                  <div class="d-flex pf-dt-sb">
                    <img alt="email" src="../../assets/phone.svg" />
                    <span class="sec-otr m-lt-loc"><span v-if="user.countryCode">{{ user.countryCode }} - </span>{{
                      user.contactNumber
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/Breadcrumb.vue";
import Loader from "../../components/common/Loader.vue";
import MyProfile from "./MyProfile.vue";
import Api from "../../common/constants/api";
import ApiCalls from "../../common/api/apiCalls";
import Role from "../../common/constants/roles";

export default {
  name: "Profile",
  data() {
    return {
      shortName: "Gary Plowan",
      showLoader: false,
      plantPoLeader: true,
      plantPoPillarLeader: true,
      regionaPOManger: true,
      regionalPOPillarLeaders: true,
      globalPOLeaders: true,
      globalPOPillarLeaders: true,
      executive: true,
      plantPLTLeaders: true,
      configureUserCount: null,
      associateUser: true,
      executiveUser: true,
      corp: true,
      plantSubPillarLeaders: true,
      roles: Role,
      profileHierarchy: {},
    };
  },
  components: {
    Breadcrumb,
    Loader,
    MyProfile,
  },
  computed: {
    checkPersonaForEditProBtn() {
      return !(
        this.$oidc.userProfile.roles.includes(this.roles.CORPORATE) ||
        this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.CODELELVEADMIN) ||
        this.$oidc.userProfile.roles.includes(this.roles.SYSTEMLEVELADMIN) ||
        this.$oidc.userProfile.roles.includes(this.roles.FUNCTIONALADMIN) ||
        this.$oidc.userProfile.roles.includes(this.roles.POENERGYADMIN) 
      )

    },
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 0,
      subMenu: 1,
    });
    this.$store.dispatch("breadcrumb", {
      title: "My Profile",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "My Profile", primary: false },
      ],
    });
  },
  created() {
    this.getProfileDetails();
  },
  methods: {
    configProfile() {
      this.$router.push("configure-profile");
    },
    setAltImg(type, index) {
      this.profileHierarchy[type][index]["profilePicUrl"] = "";
    },
    editUser() {
      this.$router.push("edit-user-home");
    },
    myProfile() {
      this.$router.push("edit-my-profile");
    },
    getProfileDetails() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(
        Api.PROFILEHIRARCHY + this.$oidc.userProfile.unique_name,
        "get"
      ).then((data) => {
        this.showLoader = false;
        this.profileHierarchy = data.data;
        for (const role in this.profileHierarchy) {
          if (Object.hasOwnProperty.call(this.profileHierarchy, role)) {
            const usersArr = this.profileHierarchy[role];
            if (Array.isArray(usersArr))
              usersArr.forEach((eachuser) => {
                if (eachuser.title) {
                  let titleName = eachuser.title;
                  eachuser.title = titleName;
                }
              });
          }
        }
        this.configureUserCount = data.data.configureUserCount;
      });
    },
  },
};
</script>
<style scoped>
.my-profile-con {
  padding: 2rem;
  border-bottom: 2px solid #e8e8e8;
}

.badge {
  color: white;
}

.edit-con {
  position: absolute;
  top: 8rem;
  right: 4.5rem;
}

.asmt-bt {
  border-color: #869ed8 !important;
  background: transparent !important;
  color: #274ba7 !important;
  height: 3.5rem;
}

.asmt-bt:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.btn-text {
  margin-left: 0.5rem;
}

.my-btn {
  margin-right: 1rem;
}

.img-con {
  display: flex;
}

.profile-name {
  font-size: 1.333rem;
  font-family: "BarlowSb", sans-serif;
}

.profile-info {
  font-size: 1.166rem;
  font-family: "BarlowR", sans-serif;
}

.pillar {
  font-size: 1.166rem;
  font-family: "BarlowB", sans-serif;
}

.plant-area {
  font-size: 1.166rem;
  font-family: "BarlowM", sans-serif;
}

.details-con {
  margin-left: 1.5rem;
  word-break: break-word;
}

.m-left {
  margin-left: 1rem;
}

.m-top {
  margin-top: 0.5rem;
}

.pr-location {
  font-size: 1.166rem;
  font-family: "BarlowM", sans-serif;
}

.pro-con-lt {
  margin-left: 8rem;
}

.my-profile-con .row {
  margin-right: 0px;
  margin-left: 0px;
}

.m-lt-loc {
  margin-left: 0.5rem;
}

.my-tm-togg-con {
  border-radius: 50px;
  cursor: pointer;
  height: 25px;
  width: 25px;
  text-align: center;
  background-color: #eff3ff !important;
  border: 1px solid #274ba7 !important;
  display: flex;
  padding-left: 0.3rem;
  margin-right: 1rem;
}

.my-tm-togg-con:hover {
  background-color: #274ba727 !important;
}

.my-tm-togg-img {
  width: 1.2rem;
}

.role-btn-con {
  padding: 1.3rem;
  padding-left: 10rem;
  padding-right: 10rem;
  display: flex;
  border-bottom: 1px solid #f2f2f2;
}

.role-name {
  font-size: 1.333rem;
  font-family: "BarlowSb", sans-serif;
  color: #234372;
}

.profiles-con {
  padding-left: 10rem;
  padding-right: 10rem;
  border-bottom: 2px solid #e8e8e8;
}

.pro-img-border {
  border: 1px solid #d6d3d3;
}

.single-profile {
  padding: 1rem;
  width: 27rem;
  border-radius: 6px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 1px solid #ebebeb;
}

.green-pro {
  background: #fffdf2;
  /* background: #f2faff;  */
}

.brown-pro {
  background: #fffdf2;
}

.sec-name {
  font-size: 1.333rem;
  font-family: "BarlowSb", sans-serif;
}

.sec-bu {
  font-size: 1rem;
  font-family: "BarlowM", sans-serif;
}

.sec-otr {
  font-size: 1.083rem;
  font-family: "BarlowR", sans-serif;
}

.pf-dt-mn {
  margin-top: 0.5rem;
}

.pf-dt-sb {
  margin-top: 0.3rem;
}

.no-img {
  border-radius: 50% !important;
  border: 1px solid #d6d3d3;
  height: 6rem;
  width: 6rem;
  background: #f4f1de;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-sh {
  font-size: 2rem;
  font-family: "BarlowL", sans-serif;
}

.no-rec {
  text-align: center;
  padding: 1rem;
}

.pillar-code {
  font-size: 1rem;
  font-family: "BarlowB", sans-serif;
}
</style>